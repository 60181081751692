
const css = `/* video-gallery.css */

/* Container for the entire gallery */
.video-gallery {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
}

/* Grid layout for video thumbnails */
.video-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

/* Wrapper for individual video items */
.video-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Video item with consistent 16:9 aspect ratio */
.video-item {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9; /* Use aspect-ratio for consistent sizing */
  background: #f4f6f8;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.video-item:hover {
  transform: scale(1.02);
}

/* Thumbnail image */
.video-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Overlay gradient for title */
.video-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 30%, rgba(0,0,0,0) 50%);
  pointer-events: none;
}

/* Title of the video */
.video-item-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-shadow: 0 1px 2px rgba(0,0,0,0.6);
  z-index: 1;
}

/* Play button overlay */
.video-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transition: background 0.3s ease;
}

.video-play-button:hover {
  background: rgba(0, 0, 0, 0.9);
}

.video-play-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  border-left: 15px solid white;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

/* Modal overlay */
.video-modal-global {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 20px;
  box-sizing: border-box;
  animation: fadeIn 0.3s ease;
}

/* Modal content container */
.video-modal-content-global {
  position: relative;
  max-height: 80vh; /* Increased height to 90% of viewport */
  /*width: 100%;*/
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Allow overflow with scroll if necessary */
  aspect-ratio: 16 / 9;
  min-height: 80vh;
}

/* Wrapper for the iframe with aspect ratio */
.video-wrapper {
  position: relative;
  width: 100%;
  max-width: 1280px; /* Max width for normal videos */
  aspect-ratio: 16 / 9; /* 16:9 Aspect Ratio for normal videos */
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-wrapper.short {
  max-width: calc(80vh * 9 / 16); /* Adjusted max-width based on new max-height */
  aspect-ratio: 9 / 16; /* 9:16 Aspect Ratio for shorts */
}

/* Iframe styling */
.video-iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
}

/* Close button for the modal */
.video-modal-close-global {
  position: fixed;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 32px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  z-index: 10000;
  transition: background 0.3s ease;
}

.video-modal-close-global:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Fade-in animation for the modal */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive Design */
@media (max-width: 768px) {
  .video-modal-content-global {
    padding: 20px;
  }

  .video-modal-close-global {
    top: 10px;
    right: 10px;
  }

  .video-wrapper {
    max-width: 100%;
    aspect-ratio: 16 / 9; /* Maintain 16:9 on smaller screens */
  }

  .video-wrapper.short {
    max-width: 100%;
    aspect-ratio: 9 / 16; /* Maintain 9:16 on smaller screens */
  }
}

/* Additional Styles for Video Items */
.video-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.video-item-title {
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: flex !important;
}

.video-title-global {
  display: none;
}

/* Responsive grid adjustments */
@media (max-width: 768px) {
  .video-container {
    grid-template-columns: 1fr !important; /* Force single column on mobile */
  }
}
`;
export default css;
