/* Splide slider styles */
.splide {
  padding: 1rem;
  position: relative;
  margin: 0 auto;
}

/* Hide screen reader element without affecting layout */
.splide__sr {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.splide__track {
  overflow: hidden;
  position: relative;
}

.splide__list {
  display: flex;
  margin: 0;
  padding: 0;
  width: max-content;
  list-style: none;


  
  height: 100%;
  min-width: 100%;
}

.splide__slide {
  flex-shrink: 0;
  padding: 0 0.5rem;
  position: relative;
  width: calc(100% / 3); /* Default for 3 slides per view */
  list-style: none;
}

.splide__slide::before,
.splide__slide::after {
  display: none;
}

.splide__list::before,
.splide__list::after {
  display: none;
}

.slider-video-item {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  background: #f4f6f8;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.slider-video-item:hover {
  transform: scale(1.02);
}

.loading-slider {
  text-align: center;
  padding: 2rem;
  font-size: 1.1rem;
  color: #666;
}

/* Splide navigation arrows */
.splide__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.splide__arrow--prev {
  left: -1.5rem;
}

.splide__arrow--prev svg {
  transform: scaleX(-1); /* Flip the arrow horizontally */
}

.splide__arrow--next {
  right: -1.5rem;
}

.splide__arrow:hover {
  background: #fff;
}

.splide__arrow svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: #333;
}

/* Hide pagination */
.splide__pagination {
  display: none;
} 