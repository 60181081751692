// VideoGalleryComponent.tsx

import { h, VNode } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import * as ReactDOM from 'preact/compat';
import { createElement, Component } from 'preact/compat';
import reactToWebComponent from 'react-to-webcomponent';

// Import CSS
import styles from './video-gallery.css';
import sliderStyles from './splide.css';

// Function to inject styles
const injectStyles = () => {
  // Inject base styles
  const baseStyleId = 'video-gallery-styles';
  if (!document.getElementById(baseStyleId)) {
    const baseStyle = document.createElement('style');
    baseStyle.id = baseStyleId;
    baseStyle.textContent = styles;
    document.head.appendChild(baseStyle);
  }

  // Inject slider styles
  const sliderStyleId = 'video-gallery-slider-styles';
  if (!document.getElementById(sliderStyleId)) {
    const sliderStyle = document.createElement('style');
    sliderStyle.id = sliderStyleId;
    sliderStyle.textContent = sliderStyles;
    document.head.appendChild(sliderStyle);
  }
};

// Import Splide only when needed
const loadSplide = async () => {
  // @ts-ignore
  const { Splide, SplideSlide } = await import('@splidejs/react-splide');
  await import('@splidejs/splide/dist/css/splide.min.css');
  return { Splide, SplideSlide };
};

interface Video {
  id: number;
  name: string;
  youtubeId: string;
  isShort?: boolean;
  title?: string;
  thumbnailUrl?: string;
}

interface GallerySettings {
  textColor?: string;
  playButtonColor?: string;
  playButtonBgColor?: string;
  overlayBgColor?: string;
  closeButtonColor?: string;
  closeButtonBgColor?: string;
  videosPerRow?: number;
  layout?: 'grid' | 'slider';
}

const VideoGalleryComponent = ({ 'gallery-id': galleryId }: { 'gallery-id': string }) => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [activeVideo, setActiveVideo] = useState<string | null>(null);
  const portalContainer = useRef<HTMLDivElement | null>(null);
  const [settings, setSettings] = useState<GallerySettings>({
    textColor: '#ffffff',
    playButtonColor: '#ffffff',
    playButtonBgColor: 'rgba(0, 0, 0, 0.7)',
    overlayBgColor: 'rgba(0, 0, 0, 0.9)',
    closeButtonColor: '#ffffff',
    closeButtonBgColor: 'rgba(0, 0, 0, 0.5)',
    videosPerRow: 3,
    layout: 'grid'
  });
  const [SplideComponent, setSplideComponent] = useState<any>(null);
  const [SplideSlideComponent, setSplideSlideComponent] = useState<any>(null);

  // Inject styles on mount
  useEffect(() => {
    injectStyles();
  }, []);

  // Load Splide when layout is 'slider'
  useEffect(() => {
    if (settings.layout === 'slider') {
      loadSplide().then(({ Splide, SplideSlide }) => {
        setSplideComponent(() => Splide);
        setSplideSlideComponent(() => SplideSlide);
      });
    }
  }, [settings.layout]);

  // Create portal container on mount
  useEffect(() => {
    portalContainer.current = document.createElement('div');
    portalContainer.current.id = `video-modal-portal-${Math.random().toString(36).substr(2, 9)}`;
    document.body.appendChild(portalContainer.current);

    return () => {
      if (portalContainer.current) {
        document.body.removeChild(portalContainer.current);
      }
    };
  }, []);

  // Fetch gallery data
  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const response = await fetch(`https://video-gallery-psi.vercel.app/galleries/${galleryId}`);
        const data = await response.json();
        
        if (data.error) {
          console.error('Gallery error:', data.error);
          return;
        }

        setVideos(data.gallery.videos);
        setSettings({
          textColor: data.gallery.textColor || '#ffffff',
          playButtonColor: data.gallery.playButtonColor || '#ffffff',
          playButtonBgColor: data.gallery.playButtonBgColor || 'rgba(0, 0, 0, 0.7)',
          overlayBgColor: data.gallery.overlayBgColor || 'rgba(0, 0, 0, 0.9)',
          closeButtonColor: data.gallery.closeButtonColor || '#ffffff',
          closeButtonBgColor: data.gallery.closeButtonBgColor || 'rgba(0, 0, 0, 0.5)',
          videosPerRow: data.gallery.videosPerRow || 3,
          layout: data.gallery.layout || 'grid'
        });
      } catch (error) {
        console.error('Error loading gallery:', error);
      }
    };

    if (galleryId) {
      fetchGallery();
    }
  }, [galleryId]);

  const openVideo = (youtubeId: string) => {
    setActiveVideo(youtubeId);
    document.body.style.overflow = 'hidden';
  };

  const closeVideo = () => {
    setActiveVideo(null);
    document.body.style.overflow = 'auto';
  };

  const renderVideoItem = (video: Video, isSlider = false) => (
    <div
      key={video.id}
      className={isSlider ? 'slider-video-item' : 'video-item'}
      onClick={() => openVideo(video.youtubeId)}
      style={{ color: settings.textColor }} // Apply textColor to the container
    >
      <img
        className="video-thumbnail"
        src={video.thumbnailUrl || `https://img.youtube.com/vi/${video.youtubeId}/maxresdefault.jpg`}
        alt={video.title || video.name}
        onError={(e) => {
          const img = e.target as HTMLImageElement;
          if (img.src.includes('maxresdefault')) {
            img.src = `https://img.youtube.com/vi/${video.youtubeId}/hqdefault.jpg`;
          }
        }}
      />
      <h3 className="video-item-title" style={{ color: settings.textColor }}>{/* Apply textColor */}
        {video.title || video.name}
      </h3>
      {/* Play Button with customized colors */}
      <div
        className="video-play-button"
        style={{
          backgroundColor: settings.playButtonBgColor,
          borderColor: settings.playButtonColor
        }}
      >
        {/* Optionally, you can use an SVG or icon and set its color */}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill={settings.playButtonColor}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 5v14l11-7z" />
        </svg>
      </div>
    </div>
  );

  const renderGallery = () => {
    if (settings.layout === 'slider' && SplideComponent && SplideSlideComponent) {
      return (
        <SplideComponent
          options={{
            type: 'slide',
            perPage: settings.videosPerRow,
            perMove: 1,
            gap: '1rem',
            padding: { left: '1rem', right: '1rem' },
            pagination: true,
            arrows: true,
            drag: true,
            snap: true,
            rewind: true,
            breakpoints: {
              768: {
                perPage: Math.min(2, settings.videosPerRow || 3),
                gap: '0.5rem',
                padding: { left: '0.5rem', right: '0.5rem' }
              },
              480: {
                perPage: 1,
                gap: '0.5rem',
                padding: { left: '0.5rem', right: '0.5rem' }
              }
            }
          }}
        >
          {videos.map(video => (
            <SplideSlideComponent key={video.id}>
              {renderVideoItem(video, true)}
            </SplideSlideComponent>
          ))}
        </SplideComponent>
      );
    }

    return (
      <div
        className="video-container"
        style={{
          gridTemplateColumns: `repeat(${settings.videosPerRow}, 1fr)`
        }}
      >
        {videos.map(video => (
          <div key={video.id} className="video-item-wrapper">
            {renderVideoItem(video)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={`video-gallery video-gallery-${galleryId}`}>
      {settings.layout === 'slider' && !SplideComponent ? (
        <div className="loading-slider">Loading slider...</div>
      ) : (
        renderGallery()
      )}

      {activeVideo && portalContainer.current && ReactDOM.createPortal(
        <div
          className="video-modal-global"
          onClick={closeVideo}
          style={{ backgroundColor: settings.overlayBgColor }} // Apply overlayBgColor
        >
          <div
            className={`video-modal-content-global ${videos.find(v => v.youtubeId === activeVideo)?.isShort ? 'short' : ''}`}
            onClick={(e) => e.stopPropagation()}
          >
            {/* Close Button with customized colors */}
            <div
              className="video-modal-close-global"
              style={{
                color: settings.closeButtonColor,
                backgroundColor: settings.closeButtonBgColor
              }}
              onClick={closeVideo}
            >
              &times;
            </div>
            {videos.map(video => video.youtubeId === activeVideo && (
              <div
                key={video.id}
                className={`video-wrapper ${video.isShort ? 'short' : 'normal'}`}
              >
                <iframe
                  src={`https://www.youtube.com/embed/${activeVideo}?autoplay=1`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="video-iframe"
                />
              </div>
            ))}
          </div>
        </div>,
        portalContainer.current
      ) as any}
    </div>
  );
};

// Convert Preact component to Web Component
const VideoGalleryWebComponent = reactToWebComponent(
  VideoGalleryComponent,
  {
    createElement: createElement as any,
    Component: Component as any,
  },
  ReactDOM as any,
  {
    props: ['gallery-id'],
    shadow: false,
  }
);

// Register the Web Component
if (!customElements.get('video-gallery')) {
  customElements.define('video-gallery', VideoGalleryWebComponent);
}

export default VideoGalleryWebComponent;
